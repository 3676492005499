import { useState, useCallback } from 'react';

import { refetchTokens } from '../utils/refetchTokens';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useHttp = (relativeUrl = '') => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const request = useCallback(async (options = {}) => {
        const token = localStorage.getItem('token');

        const defaultOptions = (t) => ({
            headers: {
                'Authorization': `Bearer ${t}`,
            },
            cors: 'cors'
        });
        const mergedOptions = () => ({ ...defaultOptions(token), ...options });
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/${relativeUrl}`, mergedOptions());
            if (response.status == 401) {
                refetchTokens()
                    .then(() => {
                        const token = localStorage.getItem('token');
                        fetch(`${BASE_URL}/${relativeUrl}`, defaultOptions(token))
                            .then(response => {
                                const data = response.json();
                                setData(data);
                            });

                    });
            }
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error?.message);
            }

            const data = await response.json();
            setData(data);
        } catch (error) {
            setError(error);

        } finally {
            setIsLoading(false);
        }
    }, [relativeUrl]);

    return [data, request, error, isLoading, setError];
};

export default useHttp;