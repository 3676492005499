import React, { useCallback, useState, memo, useEffect, useMemo } from 'react';
import { Box, Container } from '@mui/material';
import styled from '@emotion/styled';

import useHttp from '../../hooks/useHttp';

import { Chat } from '../Chat/Chat';
import { EnterYourData } from './EnterYourData';
import { Dialog } from '../Dialog/Dialog';

import { getDayName } from '../../utils';

import { ReactComponent as ChatIcon } from '../../assets/icons/chatbutton.svg';

import { socket } from '../../index';

import { format, toZonedTime } from 'date-fns-tz';

import { refetchTokens } from '../../utils/refetchTokens';
import { BASE_URL } from '../../hooks/useHttp';
import { translates } from '../../constants/translates';

const widgetId = localStorage.getItem('widgetId');

// widget & button sizes
const cW = 360;
const cH = 600;
const bntSize = 64;

const MainStyled = styled(Container)(({ theme }) => ({
  overflow: 'hidden',
  position: 'fixed',
  height: '100%',
  maxHeight: '600px',
  borderRadius: theme.shape.window,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    borderRadius: theme.shape.window + 10,
    backgroundColor: '#fff',
    backgroundColor: theme.palette.primary.background,
  },
}));

export const Main = ({
  radius,
  name,
  schedule,
  titleMessages,
  timezone,
  channels,
  logotype,
  defaultLang,
  setIsTokenChanged,
  requestedFields,
  visitorMessages,
  copyDisabled,
  position = 'bottom-right',
  margins = [10, 10],
  timeout = 0,
  showWidget = true,
}) => {
  const isDialogActive = localStorage.getItem('dialogStatus') === 'active';
  const [isChatActive, setChatActive] = useState(false);
  const [isWidgetShown, setWidgetShown] = useState(true);
  const [isRequestpersonalDataShown, setRequestpersonalDataShown] =
    useState(false);
  const [isLeaveDialogBlockShown, setLeaveDialogBlockShown] = useState(false);
  const [isAddYourDataVisible, setIsAddYourDataVisible] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [selfClosed, setSelfClosed] = useState(true);
  const [messageText, setMessageText] = useState('');

  const [dialogId, fetchDialogId] = useHttp(`widget/${widgetId}/dialog`);
  const [messages, setMessages] = useState([]);
  const [isPreload, setPreload] = useState(false);

  const [messagesData, messagesRequest, isLoading] = useHttp(
    `widget/${widgetId}/dialog/${dialogId?.data?.id}${
      isPreload ? '?preload=1' : ''
    }`
  );
  const [message, sendMessageRequest, error] = useHttp(
    `widget/${widgetId}/dialog/${dialogId?.data?.id}?text=${encodeURIComponent(
      messageText
    )}`
  );
  const [_, closeDialog] = useHttp(
    `widget/${widgetId}/dialog/${dialogId?.data?.id}/close`
  );

  const currentDate = new Date();
  const zonedDate = toZonedTime(currentDate, timezone);
  const scheduleDay = getDayName(currentDate);
  const scheduleStart = new Date(
    `${zonedDate.toDateString()} ${schedule[scheduleDay]?.start}`
  );
  const end = new Date(
    `${zonedDate.toDateString()} ${schedule[scheduleDay]?.end}`
  );
  const isInSchedule = schedule[scheduleDay]?.active
    ? scheduleStart < zonedDate && end > zonedDate
    : false;


  const toggleChat = useCallback(() => {
    if (isPreload) {
      setPreload(false);
    }
    setWidgetShown((prev) => !prev);
  }, [isWidgetShown]);

  const preloadMessagesHandler = useCallback(() => {
    setTimeout(() => {
      setPreload(true);
    }, 10);
  }, [dialogId]);

  // useEffect(() => {
  //   if (!isWidgetShown && showWidget) {
  //     setWidgetShown(true);
  //   }
  // }, [showWidget, isWidgetShown]);

  useEffect(() => {
    if (isPreload) {
      messagesRequest();
    }
  }, [isPreload]);

  const membersData = useMemo(
    () =>
      (messagesData?.data?.status === 'unresolved' &&
        messagesData?.data?.chat?.members) ||
      [],
    [messagesData]
  );

  const sendMessage = useCallback(
    (messageText) => {
      if (messageText.trim().length === 0) return;
      setMessageText(messageText);
    },
    [dialogId]
  );

  const closeDialogFn = useCallback(
    (fromAdmin = false) => {
      if (!isWidgetShown) {
        setChatActive(false);
        setLeaveDialogBlockShown(false);
        setWidgetShown(true);
      } else if (fromAdmin) {
        setWidgetShown(false);
        setChatActive(true);
      }
    },
    [isWidgetShown]
  );

  const hideYourDataHandler = useCallback(() => {
    setIsAddYourDataVisible((prev) => !prev);
    setRequestpersonalDataShown((prev) => !prev);
  }, [isRequestpersonalDataShown, isAddYourDataVisible]);

  const closeDialogHandler = useCallback(
    (isSelfClosed = false) => {
      const closeRequest = closeDialog({
        method: 'POST',
      });
      closeRequest.then(() => {
        messagesRequest();
        closeDialogFn();
        if (isSelfClosed) {
          setSelfClosed(true);
        }
        localStorage.setItem('dialogStatus', 'closed');
      });
    },
    [isLeaveDialogBlockShown]
  );

  const sendMessageWithActiveDialog = useCallback(
    (func) => {
      if (!dialogId) {
        fetchDialogId({
          method: 'POST',
        });
        return func(dialogId);
      } else {
        func(dialogId);
      }
    },
    [dialogId]
  );
  const setHideDialogHandler = useCallback(() => {
    setLeaveDialogBlockShown(false);
  }, [isLeaveDialogBlockShown]);

  const onCancelHandler = useCallback(() => {
    setLeaveDialogBlockShown(true);
  }, [isLeaveDialogBlockShown]);

  const onShowRequestHandler = useCallback(() => {
    setRequestpersonalDataShown(true);
  }, [isRequestpersonalDataShown]);

  useEffect(() => {
    if (messageText.trim().length === 0 || !dialogId?.data?.id) return;
    sendMessageRequest({
      method: 'POST',
    });
  }, [messageText, dialogId?.data?.id]);

  useEffect(() => {
    if (error === null) {
      return;
    }
    setIsTokenChanged((prev) => !prev);
  }, [error]);

  useEffect(() => {
    if (isDialogActive && !dialogId?.data?.id) {
      fetchDialogId({
        method: 'POST',
      });
    }
  }, [isDialogActive, dialogId?.data?.id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeout(true);
    }, timeout * 1000);
    return () => clearTimeout(timer);
  }, [isTimeout]);

  useEffect(() => {
    const newMessages = [
      ...(messagesData?.data?.chat?.messages?.outcome
        ? messagesData?.data?.chat?.messages?.outcome
        : []),
      ...(messagesData?.data?.chat?.messages?.income
        ? messagesData?.data?.chat?.messages?.income
        : []),
    ];
    setMessages((prev) => [
      ...prev.filter((m) => !newMessages.find((n) => n.id === m.id)),
      ...newMessages,
    ]);
  }, [isPreload, messagesData]);

  useEffect(() => {
    //the widgets window will be opened by first message from a supporter and dialog will not have more than 1 message
    if (messages.length && messages.length < 2 && !selfClosed) {
      setWidgetShown(false);
    }
  }, [messagesData, selfClosed]);

  useEffect(() => {
    // fetcheing messages if dialog is has been created before
    if (isDialogActive && dialogId) {
      messagesRequest();
    }
  }, [isDialogActive, dialogId]);

  useEffect(() => {
    const dialogContactsHandler = () => {
      setIsAddYourDataVisible(true);
    };
    const dialogHandler = (data) => {
      const isTabActive = document.visibilityState === 'visible';
      if (data.type !== 'visitor' && !isTabActive) {
        window.messageAudio.play();
        window.parent.postMessage(
          {
            type: 'dialog.message',
            data: data,
          },
          '*'
        );
      }
      messagesRequest();
      localStorage.setItem('dialogStatus', 'active');
    };

    const dialogPassHandler = () => {
      messagesRequest();
    };
    const dialogConnectHandler = () => {
      if (dialogId) {
        messagesRequest();
      }
    };
    const dialogStatusHandler = ({ status }) => {
      localStorage.setItem(
        'dialogStatus',
        status === 'unresolved' ? 'active' : 'closed'
      );
      // here is need to set selfClosed to false for the case when dialog is opened by admin
      setSelfClosed(false);
      // --- the admin avatar didn't hide after dialog was closed by admin
      // need to check other cases with it
      if (dialogId?.data?.id) {
        messagesRequest();
      }
      //  --- the admin avatar didn't hide after dialog was closed by admin
      if (status === 'resolved') {
        if (!isWidgetShown) {
          setWidgetShown(true);
        }
      } else if (status === 'unresolved') {
        if (isWidgetShown) {
          setWidgetShown(false);
        }
        // setDialogId(id);
        // messagesRequest();
      }
    };
    socket.on('dialog.contacts', dialogContactsHandler);
    socket.on('dialog.pass', dialogPassHandler);
    socket.on('dialog.connect', dialogConnectHandler);
    socket.on('dialog.status', dialogStatusHandler);
    socket.on('dialog.message.send', dialogHandler);
    socket.on('dialog.message.edit', messagesRequest);
    socket.on('dialog.message.delete', ({ id }) => {
      // delete message in messages which equals to id
      setMessages((prev) => prev.filter((m) => m.id !== id));
    });

    return () => {
      socket.off('dialog.contacts', dialogContactsHandler);
      socket.off('dialog.pass', dialogPassHandler);
      socket.off('dialog.connect', dialogConnectHandler);
      socket.off('dialog.status', dialogStatusHandler);
      socket.off('dialog.message.send', dialogHandler);
      socket.off('dialog.message.edit', messagesRequest);
      socket.off('dialog.message.delete', messagesRequest);
    };
  }, [dialogId, isTimeout, isWidgetShown]);

  useEffect(() => {
    // iframe styles and widget visual settings
    const iframe = window.parent.document.querySelector('iframe');
    if (iframe) {
      if (!isWidgetShown) {
        iframe.style.setProperty('max-width', `${cW}px`);
        iframe.style.setProperty('max-height', `${cH}px`);
        iframe.style.setProperty(
          'box-shadow',
          '0px 0px 30px 0px rgba(0, 0, 0, 0.10)'
        );
        iframe.style.setProperty('border-radius', `${radius}px`);
      } else {
        iframe.style.setProperty('box-shadow', 'none');
        iframe.style.setProperty('border-radius', '50%');
        iframe.style.setProperty('max-width', `${bntSize}px`);
        iframe.style.setProperty('max-height', `${bntSize}px`);
      }
    }
  }, [isWidgetShown]);

  useEffect(() => {
    const iframe = window.parent.document.querySelector('iframe');
    if (iframe) {
      const boxShadow = iframe.style.getPropertyValue('box-shadow');
      if (!isWidgetShown) {
        iframe.style.setProperty('box-shadow', 'none');
      } else {
        iframe.style.setProperty('box-shadow', boxShadow);
      }
    }
  }, [isLeaveDialogBlockShown]);

  useEffect(() => {
    // iframe styles and widget visual settings
    const iframe = window.parent.document.querySelector('iframe');
    const [oW, oH] = margins;
    if (iframe) {
      switch (position) {
        case 'top-left':
          iframe.style.setProperty('top', `${oW}px`);
          iframe.style.setProperty('left', `${oH}px`);
          break;
        case 'top-right':
          iframe.style.setProperty('top', `${oW}px`);
          iframe.style.setProperty('right', `${oH}px`);
          break;
        case 'bottom-left':
          iframe.style.setProperty('bottom', `${oW}px`);
          iframe.style.setProperty('left', `${oH}px`);
          break;
        case 'bottom-right':
          iframe.style.setProperty('bottom', `${oW}px`);
          iframe.style.setProperty('right', `${oH}px`);
          break;
        default: {
          iframe.style.setProperty('bottom', `${oW}px`);
          iframe.style.setProperty('right', `${oH}px`);
        }
      }
    }
  }, []);

  if (!isTimeout) return null;

  return !isWidgetShown ? (
    <MainStyled
      position={position}
      margins={margins}
      disableGutters
      maxWidth="sm"
    >
      {isLeaveDialogBlockShown ? (
        <LeaveDialogs
          defaultLang={defaultLang}
          showChatHandler={setHideDialogHandler}
          hideChatHandler={closeDialogHandler}
        />
      ) : !isRequestpersonalDataShown ? (
        <Chat
          name={name}
          defaultLang={defaultLang}
          copyDisabled={copyDisabled}
          widgetId={widgetId}
          messagesPreload={preloadMessagesHandler}
          messagesLoading={isLoading}
          dialogId={dialogId?.data?.id}
          hasPreloadedMessages={messagesData?.data?.preload && !isPreload}
          showRequest={isAddYourDataVisible}
          isInSchedule={isInSchedule}
          isDialogActive={isDialogActive}
          messages={messages}
          visitorMessages={visitorMessages}
          members={membersData}
          logotype={logotype}
          channels={channels}
          isChatActive={isChatActive}
          sendMessage={sendMessage}
          sendMessageWithActiveDialog={sendMessageWithActiveDialog}
          titleMessages={titleMessages}
          onClick={hideYourDataHandler}
          onClose={toggleChat}
          onCancel={onCancelHandler}
          onShowRequest={onShowRequestHandler}
        />
      ) : (
        <EnterYourData
          defaultLang={defaultLang}
          dialogId={dialogId?.data?.id}
          id={dialogId?.data?.id}
          fields={requestedFields}
          onClose={hideYourDataHandler}
        />
      )}
    </MainStyled>
  ) : (
    <ChatEnterButton onClick={toggleChat} messagesAmont={1} />
  );
};

const ChatEnterButton = memo(({ onClick, sx, messagesAmont = '' }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'fixed',
        width: '64px',
        height: '64px',
        p: 2,
        bgcolor: 'button.main',
        borderRadius: '50%',
        cursor: 'pointer',
        ...sx,
      }}
    >
      <Box
        component={ChatIcon}
        sx={(theme) => {
          return {
            '& path': {
              fill: theme.palette.button.icon,
            },
          };
        }}
      >
        {messagesAmont}
      </Box>
    </Box>
  );
});

const LeaveDialogs = memo(
  ({ showChatHandler, hideChatHandler, defaultLang }) => {
    const [isShowConfirm, setShowConfirm] = useState(false);

    const toggleConfirm = useCallback(
      () => setShowConfirm((prev) => !prev),
      [isShowConfirm]
    );

    return !isShowConfirm ? (
      <Dialog
        title={translates[defaultLang].cancel_dialog}
        onCancel={showChatHandler}
        onSend={toggleConfirm}
        applyText={translates[defaultLang].close}
        cancelText={translates[defaultLang].cancel}
      />
    ) : (
      <Dialog
        title={translates[defaultLang].complete_askings}
        onCancel={hideChatHandler}
        onSend={hideChatHandler}
        applyText={translates[defaultLang].apply_btn}
        cancelText={translates[defaultLang].cancel_btn}
      />
    );
  }
);
