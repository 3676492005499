import React, { forwardRef, useEffect, useCallback, useState } from 'react';
import { Box, TextField, Popper } from '@mui/material';
import styled from '@emotion/styled';

import { useHttp } from '../../hooks';

import { ACCEPTED_FILE_TYPES } from '../../constants';

import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';

const widgetId = localStorage.getItem('widgetId');

const TIMEOUT_DURATION = 10000;

const FileUploadRowStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(2),
  paddingTop: theme.spacing(1.45),
}));

export const FileUploader = forwardRef(({ dialogId }, ref) => {
  const [file, setFile] = useState(null);

  const [, request, error, isLoading, setError] = useHttp(
    `widget/${widgetId}/dialog/${dialogId}`
  );

  useEffect(() => {
    if (!file || file.size === 0) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    request({
      method: 'POST',
      body: formData,
    });
    ref.current.value = '';
  }, [file]);

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(null);
      }, TIMEOUT_DURATION);

      return () => clearTimeout(timeout);
    }
  }, [error]);

  const handleChange = useCallback(
    (event) => {
      const selected = event.target.files[0];
      const types = ACCEPTED_FILE_TYPES;

      if (selected && types.includes(selected.type)) {
        setFile(selected);
        setError(null);
      } else {
        setFile(null);
      }
    },
    [file]
  );

  return (
    <Box sx={{ px: 2 }}>
      <TextField
        sx={{ display: 'none' }}
        inputRef={ref}
        type="file"
        onChange={handleChange}
        inputProps={{
          multiple: false,
          accept: ACCEPTED_FILE_TYPES.join(', '),
        }}
      />
      <Box
        sx={
          {
            // pb: (error || isLoading) && 1.45,
          }
        }
      >
        {/* {error && (
          <FileUploadRowStyled color={'error.light'}>
            <Box
              sx={(theme) => ({
                path: {
                  fill: theme.palette.error.light,
                },
              })}
              component={WarningIcon}
            />
            {error?.message}
          </FileUploadRowStyled>
        )} */}
        <Popper
          onClick={() => setError(null)}
          open={!!error}
          anchorEl={document.querySelector('#widget-body')}
          placement="bottom"
          sx={{
            zIndex: 1,
            maxWidth: '340px',
            width: '100%',
            bottom: '100%!important',
            top: 'auto!important',
            cursor: 'pointer',
            marginLeft: '-1.45rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 1.45,
              paddingBottom: 2,
              borderRadius: 1,
              backgroundColor: 'white',
              color: 'error.light',
            }}
          >
            <Box
              component={WarningIcon}
              sx={{
                marginRight: 1.45,
              }}
            />
            {error?.message}
            {/* допустимый размер файла 10 мб */}
          </Box>
        </Popper>
        {isLoading && (
          <FileUploadRowStyled>Loading:{file.name}</FileUploadRowStyled>
        )}
      </Box>
    </Box>
  );
});
